import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/layout/Seo"
import { IconArrowRight, IconMail2, IconPhone, IconPlace } from "../components/icons/Icons"
import Map from "../components/common/Map"
import ThankYouHeroBG from "../components/ThankYouHeroBG"
import AdditionalForms from "../components/common/AdditionalForms"
import HaveQuestions from "../components/common/HaveQuestions"
import HowItWorks from "../components/common/HowItWorks"

const WhatAreYouLookingToSell = () => {
  return (
    <Layout className={"mx-auto"} breadcrumbs={false}>
      <Seo
        title="What Are You Looking to Sell? | #1 Buyer in the US"
        description="What are you looking to sell? Sell your watches and jewelry for the most money. Fast, safe, and secure. The best online luxury goods buyer located in ..."
        canonical={"/what-are-you-looking-to-sell/"}
      />

      <AdditionalForms />

      <HowItWorks className={"w-11/12 mx-auto max-w-[1366px] border-t border-b py-10 my-10"} />

      <HaveQuestions />

      <section className={"mt-16"}>
        <Map />
      </section>
    </Layout>
  )
}

export default WhatAreYouLookingToSell
